
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

@Component
export default class ModelPageCard extends Vue {
  @Prop({ default: null })
  public card!: any;
  @Prop({ default: false })
  public editable!: boolean;
  @Prop({ default: false })
  public main!: boolean;
  // Editable, if we show delete menu etc
  // main. If we want it to look like main model page or dataset view (false)

  public async mounted() {}

  public capitalizeFirstLetter(inputString: string): string {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public computedProgress() {
    // Ensure no division by zero and card.item_count is defined
    if (this.card.progress_total > 0) {
      return Math.min((this.card.progress / this.card.progress_total) * 100, 100);
    }
    return 0.0;
  }

  public removeModel(id) {
    this.remove(id, true);
  }

  public getExportPrice(id) {
    this.exportprice(id);
  }

  public dateParse(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY h:mm A");
  }

  get isClassification() {
    return this.$router.currentRoute.path.includes("classification");
  }

  public statusCheck(model) {
    // returns true if status indicates training/uploading etc
    // if (!model?.label_containers) {
    //   if (model?.status === "training") {
    //     return true;
    //   }
    //   return false;
    // } else {
    // if (model?.status === "deployed" && model?.label_containers === undefined) {
    //   return false;
    // }

    if (model?.status === "ready") {
      if (model?.label_containers !== undefined && model?.label_containers[0].status === "ready") {
        return false;
      }
      console.log("Im returning true");
      return true;
    }
    console.log("Im returning true");
    return true;
  }

  @Emit()
  public remove(id, dialogRemove) {}

  @Emit()
  public exportprice(id) {}
}
